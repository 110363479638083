import React from 'react'
import { Helmet } from 'react-helmet'
import styled, { css } from 'styled-components'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import { Link } from 'gatsby'

import Code from './Code'
import CodeHighlighter from './CodeHighlighter'
import AnchorHeading from './AnchorHeading'
import Box from './Box'

const mdxShortcodes = {
  Link,
  Box,
  inlineCode: (props) => <Code {...props}></Code>,
  pre: (props) => <CodeHighlighter {...props} />,
  h2: (props) => <AnchorHeading {...props} tag="h2"></AnchorHeading>,
  a: ({ href, children }) =>
    href.startsWith('/') ? (
      <a as={Link} to={href}>
        {children}
      </a>
    ) : (
      <a href={href} rel="nofollow noopener noreferrer" target="_blank">
        {children}
      </a>
    ),
}

export const shortcodes = mdxShortcodes

const GlobalMDXStyle = styled.div`
  ${({ theme }) => css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: flex;
      align-items: center;
      color: ${theme.colors.neutral.gray1200};
      margin: ${theme.spacers.normal} 0;
      line-height: 1.5;
    }

    h2 {
      border-left: 5px solid ${theme.colors.main.primary};
      padding-left: ${theme.spacers.normal};
    }

    blockquote {
      margin: 0;
      padding: calc(${theme.spacers.normal} / 2) ${theme.spacers.normal};
      color: ${theme.colors.neutral.gray1500};
      background-color: ${theme.colors.accent.primaryLighten45};
      border-left: ${theme.spacers.xxsmall} solid ${theme.colors.main.primary};
    }

    strong {
      color: ${theme.colors.accent.secondaryDarken30};
    }

    a {
      text-decoration: underline solid ${theme.colors.neutral.gray900};
      color: ${theme.colors.main.secondary};
      transition: color 0.25s;

      &:hover {
        color: ${theme.colors.main.primary};
      }
    }

    blockquote > p {
      margin: 0;
    }

    .footnotes {
      word-break: break-all;
    }

    table {
      width: 100%;
      background-color: transparent;
      border-collapse: collapse;

      & thead th {
        padding: ${theme.spacers.xsmall};
        vertical-align: bottom;
        border-top: 2px solid ${theme.colors.neutral.gray300};
        border-bottom: 2px solid ${theme.colors.neutral.gray300};
      }

      & th,
      td {
        padding: ${theme.spacers.xsmall};
        vertical-align: top;
        border-top: 1px solid ${theme.colors.neutral.gray300};
      }
    }
  `}
`

function MathStyles({ includesMath }) {
  if (!includesMath) return null

  return (
    <Helmet>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.11.1/katex.min.css"
      />
    </Helmet>
  )
}

function Renderer({ children, shortcodes = mdxShortcodes, includesMath }) {
  return (
    <div>
      <MathStyles includesMath={includesMath}></MathStyles>
      <GlobalMDXStyle>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{children}</MDXRenderer>
        </MDXProvider>
      </GlobalMDXStyle>
    </div>
  )
}

export default Renderer
