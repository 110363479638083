import React from 'react'
import styled, { css } from 'styled-components'

const StyledCode = styled.code`
  ${({ theme }) => css`
    color: ${theme.colors.main.primary};
    padding: ${theme.spacers.xxsmall} ${theme.spacers.small};
    font-family: ${theme.fontFamily.code};
    font-size: ${theme.fontSizes.small};
    border-radius: ${theme.round.xlarge};
    background-color: ${theme.colors.neutral.gray200};
  `};
`

function Code(props) {
  return <StyledCode {...props}></StyledCode>
}

export default Code
