import React from 'react'
import styled, { css } from 'styled-components'
import { preToCodeBlock } from 'mdx-utils'
import Highlight, { defaultProps } from 'prism-react-renderer'
import rangeParser from 'parse-numeric-range'
import theme from 'prism-react-renderer/themes/vsDark'
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live'
import {
  DiJavascript1,
  DiRuby,
  DiPython,
  DiHtml5,
  DiCss3,
  DiSass,
  DiReact,
  DiGo,
  DiCode,
  DiDart,
} from 'react-icons/di'

const LANGUAGE_ICON_MAPPING = {
  go: {
    icon: DiGo,
    color: '#05ACD7',
    name: 'Go',
  },
  javascript: {
    icon: DiJavascript1,
    color: '#f7df1e',
    name: 'JavaScript',
  },
  typescript: {
    icon: DiJavascript1,
    color: '#284E7F',
    name: 'TypeScript',
  },
  tsx: {
    icon: DiJavascript1,
    color: '#284E7F',
    name: 'TSX',
  },
  jsx: {
    icon: DiReact,
    color: '#00D7FF',
    name: 'JSX',
  },
  html: {
    icon: DiHtml5,
    color: '#005a9c',
    name: 'HTML',
  },
  css: {
    icon: DiCss3,
    color: '#ff9800',
    name: 'CSS',
  },
  python: {
    icon: DiPython,
    color: '#366D9C',
    name: 'Python',
  },
  ruby: {
    icon: DiRuby,
    color: '#AA1400',
    name: 'Ruby',
  },
  sass: {
    icon: DiSass,
    color: '#CD6698',
    name: 'SASS',
  },
  scss: {
    icon: DiSass,
    color: '#CD6698',
    name: 'SASS',
  },
  dart: {
    icon: DiDart,
    color: '#1967D2',
    name: 'Dart',
  },
  default: {
    icon: DiCode,
    color: '#ffffff',
    name: 'Code',
  },
}

const Wrapper = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacers.normal} 0;
  overflow: auto;
`

const HighlightContent = styled.div`
  -webkit-overflow-scrolling: touch;

  ${({ theme }) => css`
    pre {
      font-family: ${theme.fontFamily.code};
      overflow-y: auto;
      margin: 0;

      &[class*='language-'] {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: ${theme.spacers.normal};
        border-top-left-radius: ${theme.round.normal};
        border-bottom-left-radius: ${theme.round.normal};
        border-bottom-right-radius: ${theme.round.normal};
        background-color: transparent;
        float: left;
        min-width: 100%;
      }
    }

    .highlight-line {
      background-color: rgb(53, 59, 69);
      display: block;
      margin-right: -1em;
      margin-left: -1em;
      padding-right: 1em;
      padding-left: 0.75em;
      border-left: 0.3em solid ${theme.colors.main.primary};
    }
  `}
`

const LineNo = styled.span`
  display: inline-block;
  width: ${({ theme }) => theme.spacers.large};
  user-select: none;
  opacity: 0.3;
`

const StyledHighlightHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: max-content;
    margin-left: auto;
    margin-top: ${theme.spacers.normal};
    background-color: ${theme.colors.neutral.gray2000};
    padding: 0 ${theme.spacers.small};
    border-top-left-radius: ${theme.round.normal};
    border-top-right-radius: ${theme.round.normal};
  `}
`

const StyledHighlightHeaderContent = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    margin-left: calc(${theme.spacers.small} / 2);
    color: ${theme.colors.neutral.white};
  `}
`

function HighlightHeader({ language, title }) {
  const { icon: Icon, color, name } =
    LANGUAGE_ICON_MAPPING[language] || LANGUAGE_ICON_MAPPING['default']

  return (
    <StyledHighlightHeader>
      <Icon color={color}></Icon>
      <StyledHighlightHeaderContent>
        {title || name}
      </StyledHighlightHeaderContent>
    </StyledHighlightHeader>
  )
}

function extractOptions(options = []) {
  const result = {}

  options.forEach((option) => {
    const [key, value] = option.split('=')

    result[key] = value
  })

  return result
}

function calculateLinesToHighlight(meta) {
  const RE = /{([\d,-]+)}/
  if (RE.test(meta)) {
    const strlineNumbers = RE.exec(meta)[1]
    const lineNumbers = rangeParser(strlineNumbers)
    return (index) => lineNumbers.includes(index + 1)
  } else {
    return () => false
  }
}

function CodeHighlighter(preProps) {
  const props = preToCodeBlock(preProps)

  if (!props) return <pre {...preProps} />

  const { codeString, language: unnormalizedLang } = props
  const [language, ...options] = unnormalizedLang.split(':')
  const { title } = extractOptions(options)

  if (props['react-live']) {
    return (
      <LiveProvider code={codeString} noInline={true} theme={theme}>
        <LiveEditor />
        <LiveError />
        <LivePreview />
      </LiveProvider>
    )
  }

  const shouldHighlightLine = calculateLinesToHighlight(props.metastring)

  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <>
          <HighlightHeader language={language} title={title}></HighlightHeader>
          <Wrapper>
            <HighlightContent data-language={language}>
              <pre className={className} style={style}>
                {tokens.map((line, i) => {
                  const lineProps = getLineProps({ line, key: i })

                  if (shouldHighlightLine(i)) {
                    lineProps.className = `${lineProps.className} highlight-line`
                  }

                  return (
                    <div key={i} {...lineProps}>
                      <LineNo>{i + 1}</LineNo>
                      {line.map((token, key) => (
                        <span {...getTokenProps({ token, key })} />
                      ))}
                    </div>
                  )
                })}
              </pre>
            </HighlightContent>
          </Wrapper>
        </>
      )}
    </Highlight>
  )
}

export default CodeHighlighter
