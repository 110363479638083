import React, { useEffect, useCallback, useRef, useContext } from 'react'

import { Context } from './AnchorHeadingProvider'

function AnchorHeading({ tag: Tag, children }) {
  const headingRef = useRef(null)
  const { heading, setCurrentHeading, addHeading } = useContext(Context)

  const handleIntersection = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          entry.target.textContent !== heading?.current?.textContent
        ) {
          setCurrentHeading(headingRef)
        }
      })
    },
    [heading, setCurrentHeading, headingRef]
  )

  useEffect(() => {
    addHeading(headingRef)
  }, [addHeading, headingRef])

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '-40% 0px -50% 0px',
      threshold: 0,
    })

    observer.observe(headingRef.current)

    return () => observer.disconnect()
  }, [handleIntersection, headingRef])

  return <Tag ref={headingRef}>{children}</Tag>
}

export default AnchorHeading
