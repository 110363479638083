import styled, { css } from 'styled-components'

const Box = styled.div`
  ${({ theme, fontSize, color, textAlign }) => css`
    ${fontSize && `font-size: ${theme.fontSizes[fontSize]}`};
    ${color && `color: ${theme.colors[color]}`};
    ${textAlign && `text-align: ${textAlign}`};
  `}
`

export default Box
