import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react'

export const Context = createContext({
  refs: [],
  heading: null,
  setCurrentHeading: () => {},
  addHeading: () => {},
})

function AnchorHeadingProvider({ children }) {
  const [refs, setRefs] = useState([])
  const [currentHeading, setCurrentHeading] = useState(null)

  const addHeading = useCallback(
    (heading) => {
      setRefs((refs) => [...refs, heading])
    },
    [setRefs]
  )

  const contextValue = useMemo(() => {
    return {
      heading: currentHeading,
      setCurrentHeading,
      refs,
      addHeading,
    }
  }, [currentHeading, setCurrentHeading, addHeading, refs])

  // Set default heading to the first one
  useEffect(() => {
    if (refs.length > 0 && !currentHeading) {
      setCurrentHeading(refs[0])
    }
  }, [refs, currentHeading])

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export default AnchorHeadingProvider
